import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Badge } from 'reactstrap';

export const buildColumns = (isAdmin: boolean, partnerID: string) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('client.code', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/partners/${row.original.id}`} className="text-light">
            #{getValue()}
          </Link>
        </Badge>
      ),
      header: 'ID do cliente',
      size: 7,
      meta: {
        isJoinColumn: true,
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('identification_number', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/contracts/${row.original.id}`} className="text-light">
            #{getValue()}
          </Link>
        </Badge>
      ),
      header: 'ID do contrato',
      size: 7,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('client.name', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/contracts/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
      header: 'Cliente',
      size: 20,
      meta: {
        isJoinColumn: true,
      },
    }),
    columnHelper.accessor('profitability_group', {
      cell: ({ row, getValue }: any) => (
        <p className="text-muted mb-0">
          {getValue().description} - <strong>{getValue().percentage?.replace('.', ',')}%</strong>
        </p>
      ),
      header: 'Grupo de rentabilidade',
      size: 10,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('description', {
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
      header: 'Descrição',
      size: 10,
    }),
    columnHelper.accessor('recurring_withdrawal', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Sim
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Não
            </Badge>
          )}
        </>
      ),
      header: 'Retirada recorrente',
      size: 5,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('active', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Ativado
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Desativado
            </Badge>
          )}
        </>
      ),
      header: 'Ativo',
      size: 5,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('partner.name', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() ? (
            <h5 className="font-size-14 mb-1">
              <Link
                to={`/contracts/${row.original.id}/link-partner/${row.original.partner_id}`}
                className="text-dark mb-0"
              >
                {getValue()}
              </Link>
            </h5>
          ) : (
            <div className="d-flex gap-3">
              <Link
                to={`/contracts/${row.original.id}/link-partner`}
                className="btn btn-primary btn-sm btn-rounded"
              >
                <i className="mdi mdi-plus-circle-outline me-1"></i> Vincular acessor
              </Link>
            </div>
          )}
        </>
      ),
      header: 'Acessor',
      size: 15,
      meta: {
        isJoinColumn: true,
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('contracts', {
      cell: ({ row }: any) => (
        <div className="d-flex gap-3">
          <Link to={`/contracts/${row.original.id}`} className="btn btn-outline-primary btn-sm">
            <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Visualizar
          </Link>
        </div>
      ),
      header: 'Contratos',
      size: 5,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
