import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import PartnersInfo from '@modules/Partners/components/Info';
import Tabs from '@modules/Partners/components/Tabs';
import { getByID } from '@modules/Partners/services';
import { getAll as getAllDocuments } from '@modules/Partners/submodules/Documents/services';

import PartnersDocuments from '@modules/Partners/submodules/Documents/pages';
import PartnersAddresses from '@modules/Partners/submodules/Addresses/pages';
import PartnersBankDatas from '@modules/Partners/submodules/BankDatas/pages';
import { PartnerExtract } from '@modules/Partners/submodules/Extracts/pages';
import PartnersContacts from '@modules/Partners/submodules/Contacts/pages';
import PartnerContracts from '@modules/Partners/submodules/Contracts/pages';
import PartnerClients from '@modules/Partners/submodules/Clients/pages';
import { Details } from '@modules/Partners/components/Details';

import logoLight from '@assets/images/logo-light.png';
import { PartnersEarnings } from '../submodules/Earnings/pages';
import PartnerPayments from '@modules/Partners/submodules/Payments/pages';

const PartnerUpdate = ({
  router,
  activePath,
  page,
  size,
  setSearchParams,
}: IWithRouter & { activePath: string }) => {
  const { partnerID } = useParams();
  const [activeTab, toggleTab] = useState<string>(activePath ?? 'edit');
  const { data, isFetching } = useQuery({
    queryKey: ['partner', partnerID],
    queryFn: () => getByID(partnerID || ''),
    retry: 1,
  });

  const { data: documents } = useQuery({
    queryKey: ['partnerDocuments', data?.id, page, size],
    queryFn: () => getAllDocuments(data?.id || '', page, size),
    initialData: { items: [], totalItems: 0 },
  });

  useEffect(() => {
    if (partnerID && activeTab) {
      const pathname = activeTab === 'details' ? '' : activeTab;
      const route = `/partners/${partnerID}/${pathname}`;

      if (route !== router.location.pathname) {
        router.navigate(`/partners/${partnerID}/${pathname}`);
      }
    }
  }, [activeTab, partnerID]);

  useEffect(() => {
    toggleTab(!activePath ? 'details' : activePath);
  }, [activePath]);

  const getPageName = {
    edit: 'Editar acessor',
    'bank-data': 'Dados bancários',
    extract: 'Extrato do acessor',
    contracts: 'Contratos do acessor',
    contacts: 'Contatos do acessor',
    documents: 'Documentos do acessor',
    addresses: 'Endereços do acessor',
    earnings: 'Ganhos do acessor',
    files: 'Arquivos do acessor',
    payments: 'Pagamentos do acessor',
  };

  return (
    <Page
      title="Editar do Acessor"
      breadcrumb={{
        title: 'Acessores',
        titleHref: `/partners`,
        breadcrumbItem: 'Editar Acessor',
      }}
    >
      <Row>
        <Col lg={12}>
          <Details
            activeTab={activeTab}
            partnerCode={data?.code || ''}
            partnerName={data?.name || ''}
            partnerDocument={documents?.items || []}
          />

          <Card>
            <Tabs activeTab={activeTab} toggleTab={toggleTab} />
            <CardBody>
              <CardTitle className="mb-4">
                <div className="logo-pdf">
                  <img src={logoLight} alt="logo" width="150" />
                </div>
                {getPageName[activeTab] ?? 'Dados cadastrais'}
              </CardTitle>
              <div className="mt-3">
                {isFetching && activeTab === 'partners' ? (
                  <Spinners />
                ) : (
                  <>
                    {activeTab === 'details' && (
                      <PartnersInfo partner={data} partnerID={partnerID} />
                    )}
                    {activeTab === 'earnings' && (
                      <PartnersEarnings
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'documents' && (
                      <PartnersDocuments
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        partnerType={data?.type}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'contacts' && (
                      <PartnersContacts
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'addresses' && (
                      <PartnersAddresses
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'bank-data' && (
                      <PartnersBankDatas
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'clients' && (
                      <PartnerClients
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'contracts' && (
                      <PartnerContracts
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'extract' && (
                      <PartnerExtract
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        partner={data}
                        documents={documents}
                        setSearchParams={setSearchParams}
                      />
                    )}
                    {activeTab === 'payments' && (
                      <PartnerPayments
                        router={router}
                        page={page}
                        size={size}
                        partnerID={partnerID}
                        setSearchParams={setSearchParams}
                      />
                    )}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PartnerUpdate);
