import React from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';

import { IWithRouter } from '@apptypes/WithRouter';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
const Reports = ({ router }: IWithRouter) => {
  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatórios',
        titleHref: '/reports',
        breadcrumbItem: 'Relatórios',
      }}
    >
      <Row>
        <Col lg={4}>
          <Card>
            <CardBody>
              <CardTitle>Relatório de transações gerais</CardTitle>
              <CardText>Exportar todas as transações dos contratos.</CardText>
              <Button
                color="primary"
                onClick={() => {
                  router.navigate('/reports/transactions');
                }}
              >
                Ir para export
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <CardTitle>Relatório de custódia</CardTitle>
              <CardText>Listagem dos relatórios de custódia.</CardText>
              <Button
                color="primary"
                onClick={() => {
                  router.navigate('/reports/custody');
                }}
              >
                Visualizar relatório de custódia
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Reports);
