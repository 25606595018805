import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { CustodyValue } from '../components/CustodyValue';
import { CustodyProjection } from '../components/CustodyProjection';
import { CustodyWithdraw } from '../components/CustodyWithdraw';
import { CustodyWithdrawRealocation } from '../components/CustodyWithdrawRealocation';
import { CustodyContractType } from '../components/CustodyContractType';
import { CustodyContractTypeValue } from '../components/CustodyContractTypeValue';
import { useQuery } from '@tanstack/react-query';
import { getCustodyReport } from '../services';
import { CustodyWithdrawRecurring } from '../components/CustodyWithdrawRecurring';
import { CustodyReportProps } from '../types';
import { custodyReportInitialData, getGraphData } from '../components/custodyUtils';

const Custody = () => {
  const [projectionMonths, setProjectionMonths] = useState<number>(1);

  const { data, isFetching } = useQuery<CustodyReportProps>({
    queryKey: ['custodyReport', projectionMonths],
    queryFn: () => getCustodyReport(projectionMonths),
    initialData: custodyReportInitialData,
    retry: 1,
  });

  const { typesOptions, valueOptions } = getGraphData(data);

  const handleChange = (value: string) => {
    setProjectionMonths(Number(value));
  };

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatório de custódia',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de custódia',
      }}
    >
      <Row>
        <Col lg="3">
          <CustodyValue
            value={data.total_custody.value}
            percentage={data.total_custody.porcentage}
            isLoading={isFetching}
          />
        </Col>
        <Col lg="3">
          <CustodyProjection
            value={data.custody_projection.value}
            isLoading={isFetching}
            projectionMonths={projectionMonths}
            handleChange={handleChange}
          />
        </Col>
        <Col lg="2">
          <CustodyWithdraw
            value={data.withdrawals.value}
            processed={data.withdrawals.processed}
            isLoading={isFetching}
          />
        </Col>
        <Col lg="2">
          <CustodyWithdrawRealocation
            value={data.withdrawals_relocation.value}
            isLoading={isFetching}
          />
        </Col>
        <Col lg="2">
          <CustodyWithdrawRecurring
            value={data.recurring_withdrawals.value}
            isLoading={isFetching}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <CustodyContractType isLoading={isFetching} options={typesOptions} />
        </Col>
        <Col lg="6">
          <CustodyContractTypeValue isLoading={isFetching} options={valueOptions} />
        </Col>
      </Row>
      s
    </Page>
  );
};

export default withRouter(Custody);
